<template>
  <section id="stringText">
    <slot></slot>

    <!-- Ajouter un nouveau texte -->
    <!-- <b-button v-if="editable" id="test" variant="primary" ref="button">
        Ajouter un texte
    </b-button>
    <b-popover
      target="test"
      triggers="click"
      placement="auto"
      ref="popover"
      container="my-container"
      @show="onShow"
      @shown="onShown"
      @hidden="onHidden"
      :show.sync="popoverShow"
    >
      <template #title>
        <b-button @click="onClose" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Interactive Content
      </template>

      <div>
        <b-form-group
          label="Type"
          label-for="popover-input-1"
          label-cols="3"
          :state="input1state"
          class="mb-1"
          description="Enter your name"
          invalid-feedback="This field is required"
        >
        <select class="btn btn-sm" v-model="atype" required>
          <option value="paragraph" selected>paragraph</option>
          <option value="titre">titre</option>
        </select>
        </b-form-group>

        <b-form-group
          label="Emplacement"
          label-for="popover-input-2"
          label-cols="3"
          :state="input2state"
          class="mb-1"
          description="Pick a color"
          invalid-feedback="This field is required"
        >
        <select class="btn btn-sm" v-model="position" required>
          <option value="before" selected>Avant</option>
          <option value="after">Après</option>
        </select>
        </b-form-group>
        <b-button @click="onClose" size="sm" variant="danger">Annuler</b-button>
        <b-button @click="push_text()" size="sm" variant="primary">Ajouter</b-button>
      </div>
    </b-popover> -->
    <!-- /Fin de Ajouter un nouveau texte -->

    <div v-if="edit && editable">
      <!-- heading text edition -->
      <input
        @focus="getdescription = items"
        v-if="items.element == 'heading'"
        type="text"
        :class="
          typography(items.data['heading-justify'], items.data['heading-type'])
        "
        class="d-flex"
        :id="'heading-' + getdescription"
        v-model="items.data['heading-text']"
      />
      <!-- {{description.data}} -->
      <div
        v-if="items.element == 'heading' || items == getdescription"
        class="{editing_text:edit_form}"
      >
        <b-popover
          :target="'heading-' + getdescription"
          placement="bottom"
          title="Titre - Typographie"
          triggers="click"
        >
          <h6 class="text-muted" for="">Thème:</h6>
          <select
            class="btn btn-block btn-sm"
            v-model="items.data['heading-type']"
          >
            <option value="h1">h1</option>
            <option value="h2">h2</option>
            <option value="h3">h3</option>
            <option value="h4">h4</option>
            <option value="h5">h5</option>
            <option value="h6">h6</option>
            <option value="h7">h7</option>
          </select>
          <hr
            role="separator"
            aria-orientation="horizontal"
            class="dropdown-divider mb-2"
          />

          <h6 class="text-muted">Alignement</h6>
          <select
            class="btn btn-block btn-sm"
            v-model="items.data['heading-justify']"
          >
            <option value="left">Gauche</option>
            <option value="center">Centré</option>
            <option value="right">Droite</option>
          </select>
          <hr
            role="separator"
            aria-orientation="horizontal"
            class="dropdown-divider mb-2"
          />
          <!-- Ajout d'un nouveau texte  -->
          <h6 class="text-muted">Ajouter un nouveau texte</h6>
          <select class="btn btn-sm" v-model="atype">
            <option value="paragraph" selected>paragraph</option>
            <option value="titre">titre</option>
          </select>
          <select class="btn btn-sm" v-model="position">
            <option value="before" selected>Avant</option>
            <option value="after">Après</option>
          </select>
          <button
            class="btn btn-outline-dark btn-block btn-sm"
            @click="push_text()"
          >
            Ajouter un nouveau texte
          </button>
          <!-- fin ajout d'un nouveau texte -->
          <br />
          <hr />
          <div class="d-flex justify-content-center">
            <button class="btn btn-danger" @click="remove_text()">
              <i class="fa fa-trash"></i>
            </button>
            <button
              class="btn btn-success"
              @click="(getdescription = null), (edit = false)"
            >
              <i class="fa fa-save"></i>
            </button>
          </div>
        </b-popover>
      </div>
      <!-- heading text edition end-->

      <!-- paragraph edition -->
      <textarea
        rows="5"
        @focus="getdescription = items"
        v-if="items.element == 'paragraph'"
        :id="'paragra-' + getdescription"
        type="text"
        class="d-flex"
        :class="paragraph_justify"
        v-model="items.data['paragraph-text']"
        :style="{
          fontSize: paragraph_size + 'em',
          fontWeight: paragraph_weight,
          fontStyle: paragraph_style,
        }"
      >
      </textarea>

      <div v-if="items.element == 'paragraph' || items == getdescription">
        <b-popover
          :target="'paragra-' + getdescription"
          placement="bottom"
          title="Typography"
          triggers="click"
        >
          <h6 class="text-muted">Alignement</h6>
          <div class="d-flex justify-content-between">
            <button
              v-if="paragraph_justify != 'left'"
              @click="change_justify('left')"
              type="button"
              class="btn btn-outline-success btn-sm"
            >
              <i class="fa fa-align-left"></i>
            </button>

            <button
              v-else
              @click="change_justify('left')"
              type="button"
              class="btn btn-dark btn-sm"
            >
              <i class="fa fa-align-left"></i>
            </button>

            <button
              v-if="paragraph_justify != 'center'"
              @click="change_justify('center')"
              type="button"
              class="btn btn-outline-success btn-sm"
            >
              <i class="fa fa-align-center"></i>
            </button>

            <button
              v-else
              @click="change_justify('left')"
              type="button"
              class="btn btn-dark btn-sm"
            >
              <i class="fa fa-align-center"></i>
            </button>

            <button
              v-if="paragraph_justify != 'right'"
              @click="change_justify('right')"
              type="button"
              class="btn btn-outline-success btn-sm"
            >
              <i class="fa fa-align-right"></i>
            </button>
            <button
              v-else
              @click="change_justify('left')"
              type="button"
              class="btn btn-outline-dark btn-sm"
            >
              <i class="fa fa-align-right"></i>
            </button>
          </div>

          <hr
            role="separator"
            aria-orientation="horizontal"
            class="dropdown-divider mb-2"
          />
          <h6 class="text-muted">Police</h6>
          <div class="d-flex justify-content-between">
            <button
              class="btn btn-outline-success btn-sm"
              @click="big_paragraphe_size"
            >
              A
            </button>
            <button
              style="font-size: 0.5em"
              class="btn btn-outline-success btn-sm"
              @click="smal_paragraphe_size"
            >
              A
            </button>

            <button
              v-if="paragraph_style == 'normal'"
              @click="change_style"
              type="button"
              class="btn btn-outline-success btn-sm"
            >
              <i class="fa fa-italic"></i>
            </button>
            <button
              v-else
              @click="change_style"
              type="button"
              class="btn btn-dark btn-sm"
            >
              <i class="fa fa-italic"></i>
            </button>

            <button
              v-if="paragraph_weight == 'normal'"
              @click="change_weight"
              type="button"
              class="btn btn-outline-success btn-sm"
            >
              <i class="fa fa-bold"></i>
            </button>
            <button
              v-else
              @click="change_weight"
              type="button"
              class="btn btn-dark btn-sm"
            >
              <i class="fa fa-bold"></i>
            </button>
          </div>
          <hr
            role="separator"
            aria-orientation="horizontal"
            class="dropdown-divider mb-2"
          />
          <h6 class="text-muted">Ajouter un nouveau texte</h6>
          <div class="d-flex justify-content-between">
            <select class="btn btn-sm" v-model="atype">
              <option value="paragraph">paragraph</option>
              <option value="titre">titre</option>
            </select>
            <select class="btn btn-sm" v-model="position">
              <option value="before">before</option>
              <option value="after">after</option>
            </select>
          </div>
          <button
            class="btn btn-outline-dark btn-block btn-sm"
            @click="push_text()"
          >
            Ajouter un nouveau texte</button
          ><br />
          <hr
            role="separator"
            aria-orientation="horizontal"
            class="dropdown-divider mb-2"
          />
          <div class="d-flex justify-content-center">
            <button class="btn btn-danger btn-sm" @click="remove_text()">
              <i class="fa fa-trash"></i>
            </button>
            <button
              class="btn btn-success"
              @click="(getdescription = null), (edit = false)"
            >
              <i class="fa fa-save"></i>
            </button>
          </div>
        </b-popover>
      </div>
      <!-- paragraph edition end-->
    </div>
    <div v-else>
      <heading
        v-if="items.element == 'heading'"
        :text="items.data['heading-text']"
        :justify="items.data['heading-justify']"
        :type="items.data['heading-type']"
        v-on:editable-heading="edit = true"
      />
      <paragraph
        v-if="items.element == 'paragraph'"
        :text="items.data['paragraph-text']"
        :justify="paragraph_justify"
        :font_size="paragraph_size"
        :font_family="items.data['paragraph-font-family']"
        :font_style="paragraph_style"
        :font_weight="paragraph_weight"
        v-on:editable-paragraph="edit = true"
      />
    </div>
    <!-- editing form  -->
  </section>
</template>

<script>
import Heading from "./text/heading.vue";
import Paragraph from "./text/paragraph.vue";
import jheading from "./json/heading.json";
import jparagraph from "./json/paragraph.json";
export default {
  name: "stringtext",
  components: {
    Heading,
    Paragraph,
  },
  data() {
    return {
      getdescription: null,
      atype: null,
      position: null,
      paragraph_size: this.items.data["paragraph-font-size"],
      paragraph_justify: this.items.data["paragraph-font-justify"],
      paragraph_style: this.items.data["paragraph-font-style"],
      paragraph_weight: this.items.data["paragraph-font-weight"],
      paragraph_prototype: jparagraph,
      heading_prototype: jheading,
      edit: false,
      // popover
      input1: "",
      input1state: null,
      input2: "",
      input2state: null,
      options: [{ text: "- Choose 1 -", value: "" }, "Red", "Green", "Blue"],
      input1Return: "",
      input2Return: "",
      popoverShow: false,
    };
  },
  computed: {},
  props: {
    items: null,
    descriptionIndex: null,
    editable: null,
    image: String,
  },
  methods: {
    typography(j, h) {
      return j + " " + h;
    },
    change_justify(justify) {
      this.paragraph_justify = justify;
      this.$emit("typographie-text", {
        value: this.paragraph_justify,
        type: "justify",
      });
    },
    big_paragraphe_size() {
      this.paragraph_size = this.paragraph_size + 0.1;
      console.log(this.paragraph_size);
      //persiste data here
      this.$emit("typographie-text", {
        value: this.paragraph_size,
        type: "size",
      });
    },
    smal_paragraphe_size() {
      this.paragraph_size = this.paragraph_size - 0.1;
      //persiste data here
      this.$emit("typographie-text", {
        value: this.paragraph_size,
        type: "size",
      });
    },
    change_style() {
      if (this.paragraph_style == "normal") {
        this.paragraph_style = "italic";
      } else {
        this.paragraph_style = "normal";
      }
      //persiste when change
      this.$emit("typographie-text", {
        value: this.paragraph_style,
        type: "style",
      });
    },
    change_weight() {
      if (this.paragraph_weight == "normal") {
        this.paragraph_weight = "bold";
      } else {
        this.paragraph_weight = "normal";
      }
      //persiste when change
      this.$emit("typographie-text", {
        value: this.paragraph_weight,
        type: "weight",
      });
    },
    remove_text() {
      //remove texte logic here
      this.getdescription = null;
      this.$emit("remove-text", this.items);
      this.edit = false;
    },
    push_text() {
      let prototype = "";
      let indexinsert = "";
      if (this.atype == "paragraph") {
        prototype = this.paragraph_prototype;
      } else {
        prototype = this.heading_prototype;
      }
      if (this.position == "before") {
        indexinsert = this.descriptionIndex;
      } else {
        indexinsert = this.descriptionIndex + 1;
      }
      this.$emit("push-text", {
        index: indexinsert,
        text: prototype,
      });
      this.edit = false;
      this.getdescription = null;
    },
    // popover
    onClose() {
      this.popoverShow = false;
    },
    onShow() {
      console.log("on show");
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      this.focusRef(this.$refs.atype);
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      this.focusRef(this.$refs.button);
    },
    focusRef(ref) {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus();
        });
      });
    },
  },
};
</script>
<style scoped>
.editing_text {
  display: none;
}
input {
  border: none;
  background: transparent;
  width: 100%;
  border-radius: 5px;
}
textarea {
  border: none;
  border-radius: 5px;
  background: transparent;
  width: 100%;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.footer a,
a:hover {
  color: white;
  text-decoration: none;
}
.banner-text {
  background: none;
  padding: 0rem 1rem;
  /* text-align: center;
      align-self: center; */
}
</style>