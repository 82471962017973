import { render, staticRenderFns } from "./string-text.vue?vue&type=template&id=02f89618&scoped=true&"
import script from "./string-text.vue?vue&type=script&lang=js&"
export * from "./string-text.vue?vue&type=script&lang=js&"
import style0 from "./string-text.vue?vue&type=style&index=0&id=02f89618&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02f89618",
  null
  
)

export default component.exports